import React, { useEffect, useState, useRef, useCallback } from "react";
import Modal from "@mui/material/Modal";
import { Navbar, ThreatEntryForm } from "../components/molecules";
import {
	ChartPageStyle,
	ChartWrapper,
	ModalFormWrapper,
	FilterWrapper,
} from "./Pages.style";
import { ReactComponent as CloseBtn } from "../assets/icons/close.svg";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import ThreatSourceLines from "../components/organism/lineCharts/ThreatSourceLines";
import { CategoryLines } from "../components/organism/lineCharts";
import ThreatSourcePie from "../components/organism/pieCharts/ThreatSourcePie";
import {
	getSelectedThreatSourceBreakdown,
	getThreatSource,
	getARegionBreakdown,
	getDropDownInfo,
} from "../api/axios";
import { toast } from "react-toastify";
import { Select } from "../components/atoms";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export default function BasicModal() {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const [isCardVisible, setIsCardVisible] = useState(false);
	const [fetchedCategoryData, setFetchedCategoryData] = useState("");
	const [fetchedRegion, setFetchedRegion] = useState("");
	const [threats, setThreats] = useState("");
  const [dropDownInfo, setDropDownInfo] = useState("");
	const [clickedThreatSourceId, setClickedThreatSourceId] = useState("");
	const [isSuccessful, setIsSuccessful] = useState(false);
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(true);
	const selectedRef = useRef(null);
	const filterOptions = [
		"week",
		"quarter1",
		"quarter2",
		"quarter3",
		"quarter4",
		"annually",
	];
	const year = [2023];
	const [filterState, setFilterState] = useState({
		threatSourceDuration: "annually",
		region: {id: 2, name:"APAC"},
		year: new Date().getFullYear(),
	});
  
	const handleFilterChange = (e) => {
    if(e.target.name === "region"){
      const node = Number(
				e.target.childNodes[e.target.selectedIndex].getAttribute("id")
			);
			setFilterState({
				...filterState,
				region: { id: node, name: e.target.value.toUpperCase() },
			});
    }
		else{
      setFilterState({
			...filterState,
			[e.target.name]: e.target.value,
		});
  }
	};
	const scrollToSection = (ref) => {
		if (ref.current) {
			ref.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	const handleClose = () => {
		setOpen(false);
		setIsSuccessful(true);
	};

	//  const handleLogout = () => {
	// 		authService.logout();
	// 	};

	async function fetchThreatSource() {

		const { data, error } = await getThreatSource();
		if (!error) {
			setThreats(data);
		} else {
			toast.error("Oops something went wrong!! Try again later");
		}
	}
  	async function fetchData() {
			const { data, error } = await getDropDownInfo();
			if (error) {
				toast.error("Oops something went wrong!! Try again later");
			} else {
        const { Internet, Email, Endpoint } = data;
				const { region } = Internet;
				setDropDownInfo({
					internet: Internet,
					email: Email,
					endpoint: Endpoint,
				});
        setRegions(region)
			}
		} 
	useEffect(() => {
		fetchThreatSource();
    fetchData();

		// Delay the appearance of the card for a smooth animation
		const delay = setTimeout(() => {
			setIsCardVisible(true);
		}, 200);

		return () => clearTimeout(delay);
	}, []);
	async function fetchClickedRegionBreakdown() {
	
	}
	const fetchClickedThreatSourceBreakdown = useCallback( async()=> {
		const response = await getSelectedThreatSourceBreakdown(
			clickedThreatSourceId.id,
			filterState.threatSourceDuration,
			filterState.year
		);
    if (!response.error) {
			setFetchedCategoryData(response.data);
		} else {
			toast.error(response.error);
      setFetchedCategoryData("");
		}
	const regionData = await getARegionBreakdown(
		clickedThreatSourceId.id,
		filterState?.region?.id,
    filterState.threatSourceDuration,
		filterState.year
	);
	if (!regionData.error) {
		setFetchedRegion(regionData.data);
	} else {
		toast.error(regionData.error);
    setFetchedRegion("")
	}

	
	},[clickedThreatSourceId, filterState])

	useEffect(() => {
		filterState.threatSourceDuration &&
			clickedThreatSourceId &&
			fetchClickedThreatSourceBreakdown();
		if (clickedThreatSourceId.id === 1 && filterState?.threatSourceDuration) {
			
      fetchClickedRegionBreakdown()
		} 

	}, [
		clickedThreatSourceId,
		filterState,
		fetchClickedThreatSourceBreakdown,
	]);

	useEffect(() => {
		setIsSuccessful(false);
	}, [isSuccessful]);
	return (
		<ChartPageStyle>
			<Navbar handleOpen={handleOpen}/>
			<ChartWrapper>
				{!loading && (
					<FilterWrapper>
						<p>Filter by:</p>
						<div>
							<Select
								name="threatSourceDuration"
								data={filterOptions}
								formData={filterState}
								handleChange={handleFilterChange}
								placeholder={"Choose duration"}
								defaulValue={filterState.threatSourceDuration}
							/>
							<Select
								name="year"
								data={year}
								formData={filterState}
								handleChange={handleFilterChange}
								defaulValue={filterState.year}
								placeholder={"Choose year"}
							/>
						</div>
					</FilterWrapper>
				)}
				<div className={`main-chart`}></div>
				<div className={`chart-wrapper ${isCardVisible ? "visible" : ""}`}>
					<ThreatSourceLines
						setId={setClickedThreatSourceId}
						scrollToSection={scrollToSection}
						filter={filterState}
						setLoading={setLoading}
						loading={loading}
					/>
				</div>
				{fetchedCategoryData ? (
					<div className={`selected-wrapper `} ref={selectedRef}>
						<div className="title-wrapper">
							<p>
								<span>{clickedThreatSourceId.name}</span> threats breakdown by
								categories
							</p>
						</div>
						<div className="charts-breakdown">
							<div className={`selected-chart-wrapper`}>
								<CategoryLines data={fetchedCategoryData} />
							</div>
							<div className={`selected-chart-wrapper pie`}>
								<ThreatSourcePie data={fetchedCategoryData} />
							</div>
						</div>
					</div>
				) : null}
			</ChartWrapper>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<ModalFormWrapper>
					<div className="top">
						<h2>Update threat data</h2>
						<div className="close">
							<CloseBtn onClick={handleClose} />
						</div>
					</div>
					<div className="edit-container">
						<ThreatEntryForm
							dropDownInfo={dropDownInfo}
							handleClose={handleClose}
							threats={threats}
							setIsSuccessful={setIsSuccessful}
						/>
					</div>
				</ModalFormWrapper>
			</Modal>
		</ChartPageStyle>
	);
}
