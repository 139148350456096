export const formattedtDataForPie = (info)=>{
    const result = [];
let email = info.filter((threat)=>threat?.threatsource?.source?.toLowerCase() === "email" ).reduce((a,c)=>{
       return a + c.frequency
    },0);
let internet = info.filter((threat)=>threat?.threatsource?.source?.toLowerCase() === "internet" ).reduce((a,c)=>{
       return a + c.frequency
    },0);
let ussd = info.filter((threat)=>threat?.threatsource?.source?.toLowerCase() === "ussd" ).reduce((a,c)=>{
       return a + c.frequency
    },0);
    result.push(email,internet, ussd)
 return result;
}


export const formattedtDataForPieChart = (info)=>{
    const dataKey = Object.keys(info);
    let final = []
    for(let dataKeyIndex = 0; dataKeyIndex < dataKey?.length; dataKeyIndex++){
        let keyDateArr =Object.keys( info[dataKey[dataKeyIndex]]);
        let result = [];
        for(let keyDateArrIndex = 0; keyDateArrIndex< keyDateArr.length; keyDateArrIndex++){
            result.push(info[dataKey[dataKeyIndex]][keyDateArr[keyDateArrIndex]]?.frequency ?? 0);
        }
       final.push(result?.reduce((a,curr)=>{
            return a + curr
        },0));
    }
return final
}


