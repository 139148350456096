import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from './authContext';
import { MsalProvider } from '@azure/msal-react';
// import "./styles/index.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './config';

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App/>
    </MsalProvider>
		{/* <AuthProvider>
			<App />
		</AuthProvider> */}
		<ToastContainer />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();





// const AppWithAuthentication = () => (
// 	<MsalProvider instance={pca}>
// 		<App />
// 	</MsalProvider>
// );

// ReactDOM.render(<AppWithAuthentication />, document.getElementById("root"));