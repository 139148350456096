import React from "react";
import { SelectStyle } from "./Atoms.Style";

const Select = ({
	data,
	name,
	handleChange,
	placeholder,
	formData,
	dataObj,
	extra,
	defaulValue
}) => {
	return (
		<SelectStyle name={name} value={formData?.name} onChange={handleChange}>
			{placeholder ? (
				<option value={null} selected={!defaulValue} disabled>
					{placeholder}
				</option>
			) : null}
			{dataObj
				? dataObj?.map((option, index) => (
						<option
							key={index}
							value={option?.name.toLowerCase()}
							id={option.id}
							selected={defaulValue && option.name === defaulValue}>
							{option?.name}
						</option>
				  ))
				: data?.map((option, index) => (
						<option
							key={index}
							value={typeof option === "string" && option?.toLowerCase()}
							selected={defaulValue && option === defaulValue}>
							{option}
						</option>
				  ))}
			{extra ? (
				<option value={extra?.toLowerCase()} id="">
					{extra}
				</option>
			) : null}
		</SelectStyle>
	);
};

export default Select;
