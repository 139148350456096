import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const ThreatLines = ({data, compRef, options, datasetIdKey}) => {
  return (
    <div className="chart">
   {data.datasets && <Line options={options} data={data} ref={compRef} datasetIdKey={datasetIdKey} />}
   </div>
  )
}

export default ThreatLines