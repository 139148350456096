import React from "react";
import logo from "../assets/images/logo.png";
import { Button } from "../components/atoms";
import { WelcomePageStyle } from "./Pages.style";
// import { useAuth } from '../authContext'
import { useMsal } from "@azure/msal-react";
const Welcome = () => {
	const { instance } = useMsal();

	const handleLogin = () => {
		instance
			.loginPopup({
				redirectUri: "https://sec-dashboard.onrender.com/", // Make sure this matches your Azure AD configuration
			})
			.catch((e) => {
				console.log(e);
			});
	};
	return (
		<WelcomePageStyle>
			<div id="wrapper">
				<img src={logo} alt="Armacell's logo" />
			</div>
			<Button onclick={handleLogin} fill large>
				Click here to continue
			</Button>
		</WelcomePageStyle>
	);
};

export default Welcome;
