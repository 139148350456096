import {
	BrowserRouter as Router,
	Route,
	Routes,
} from "react-router-dom";
import { Welcome, Charts } from './pages'
import "./App.css"
// import authService from "./auth";
// import {  useAuth } from "./authContext";

import { useIsAuthenticated } from "@azure/msal-react";

function App() {
const authenticated = useIsAuthenticated();

  return (
		<Router>
				<Routes>
					{/* <Route path="/" element={authService.isAuthenticated() ? (
						<Charts />
					) : (
						<Welcome />
					)} /> */}
					<Route path="/" element={authenticated ? <Charts /> : <Welcome />} />
				</Routes>
		</Router>
	);
}

export default App;
