import React, { useState } from "react";
import { Button } from "../../atoms";
import Field from "../Field";
import { initialDataEntryState } from "./data";
import { FormStyle } from "./Form.style";
import { toast } from "react-toastify";
import { updateThreatData } from "../../../api/axios";

const ThreatEntryForm = ({ handleClose, threats, setIsSuccessful, dropDownInfo }) => {
	const [formData, setFormData] = useState(initialDataEntryState);
	
	const handleSelectChange = (event) => {
		const node = Number(
			event.target.childNodes[event.target.selectedIndex].getAttribute("id")
		);
		setFormData({
			...formData,
			[event.target.name]: { id: node, name: event.target.value },
		});
	};

	const handleChange = (event) => {
		if (event.target.name === "frequency") {
			setFormData({
				...formData,
				[event.target.name]: Number(event.target.value),
			});
		} else {
			setFormData({ ...formData, [event.target.name]: event.target.value });
		}
	};
	const formComplete =
		formData.category.id === 4
			? formData.threatSource.name &&
			  formData.category.name &&
			  formData.frequency &&
			  formData.date &&
			  formData.categoryDescription &&
			  formData.others
			: formData.threatSource.name &&
			  formData.category.name &&
			  formData.frequency &&
			  formData.date
	const handleSubmit = async(e) => {
		e.preventDefault();
		if (!formComplete) {
			toast.error("All fields are required");
		}else{
		const formatedFormData = {
			frequency: formData?.frequency,
			createdAt: formData?.date,
			threatSourceId: formData?.threatSource?.id,
			categoryId: formData?.category?.id,
			newCategory: formData.newCategory,
			categoryDescription: formData?.categoryDescription
		};
		const { response, error } = await updateThreatData(formatedFormData);
		if (!error) {
			setIsSuccessful(true);
			toast.success(response);
			setFormData({
				...formData,
				category: {
					id: "",
					name: "",
				},
				newCategory: "",
				categoryDescription: "",
				frequency: "",
			});
		} else {
			toast.error("Oops something went wrong!! Try again later");
		}
		}
	};
	return (
		<FormStyle>
			<form onSubmit={handleSubmit}>
				<Field
					htmlFor="threatSource"
					name="threatSource"
					label="Threat Source"
					type="select"
					placeholder="Select threat source"
					dataObj={threats}
					formData={formData}
					handleChange={handleSelectChange}
				/>
				<Field
					htmlFor="date"
					name="date"
					label="Date"
					inputType="date"
					placeholder="Select Date"
					formData={formData}
					handleChange={handleChange}
					max={new Date().toISOString().split("T")[0]}
				/>
				{
					<Field
						htmlFor="category"
						name="category"
						label="Category"
						type="select"
						placeholder={
							formData?.threatSource?.name !== ""
								? "Select a category"
								: "Select a threat source in the field above"
						}
						dataObj={dropDownInfo[formData.threatSource.name]?.category || null}
						formData={formData}
						handleChange={handleSelectChange}
						extra={formData?.threatSource?.name && "Others"}
					/>
				}
				{formData.category.name === "others" ? (
					<>
						<Field
							htmlFor="others"
							name="newCategory"
							label="Others"
							inputType="text"
							placeholder="Enter a new category"
							formData={formData}
							handleChange={handleChange}
						/>
						<Field
							htmlFor="categoryDescription"
							name="categoryDescription"
							label="Category Description"
							inputType="text"
							placeholder="Describe the category"
							formData={formData}
							handleChange={handleChange}
						/>
					</>
				) : null}
				{formData.category.name ? (
					<Field
						htmlFor="frequency"
						name="frequency"
						label="Frequency"
						inputType="number"
						placeholder="Enter frequency here..."
						formData={formData}
						handleChange={handleChange}
						min={0}
					/>
				) : null}
				{dropDownInfo[formData.threatSource.name]?.region?.length > 0 &&
				formData.threatSource.id === 1 ? (
					<Field
						htmlFor="region"
						name="region"
						label="Region"
						type="select"
						placeholder="Select a region"
						dataObj={dropDownInfo[formData.threatSource.name]?.region || null}
						formData={formData}
						handleChange={handleSelectChange}
					/>
				) : null}
				<div className="threat-btn">
					<Button fill="fill">Send</Button>
				</div>
			</form>
		</FormStyle>
	);
};

export default ThreatEntryForm;
