import {chartData} from '../../../pages/data'
import {formattedtDataForPie} from '../../../utils/formatDataForPie'

const colors = [ '#2685CB', '#FEC81B', '#FD8D14', '#CE00E6', '#4B4AD3', '#FC3026', '#B8CCE3', '#6ADC88', '#FEE45F'  ];



export const LinedataSetNew = ( data) =>{
  let dataKeys = data && Object.keys(data);

return dataKeys?.map((key, index)=>{
  let borderDash = 0;
  return {
		id: key === "email" ? 2 : key === "endpoint" ? 3 : 1,
		label: key.charAt(0).toUpperCase() + key.slice(1),
		data: data[key]?.map((iinfo) => iinfo?.frequency),
		borderColor: colors[index],
		pointRadius: 0,
		borderDash: [index % 3 === 0 ? borderDash + 3 : 0],
	};
})
}
   export const threatSourcePieData = {
    labels: ["internet", "email", "ussd"],
    datasets: [
      {
        label: 'Number of threats',
        data: formattedtDataForPie(chartData),
         backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
         ],
         borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
         ],
        borderWidth: 1,
      }],
  }

  
