import moment from "moment";

const formatThreatDate = (info) =>{
let dateArry =  Object.keys(info).sort((left,right)=> moment.utc(left.date).diff(moment.utc(right.date)) );
let newFormat = dateArry.map((data)=>{
    let newData = {
        threatSourceId: info[data]?.threatSourceId,
        frequency: info[data]?.frequency ?? 0,
        date: data
    }
    return newData
})

 return newFormat;
}

export const formattedThreatSourceChartData = (info)=>{
const {Email, Endpoint, Internet} = info
let formatedEndpoint = Endpoint && formatThreatDate(Endpoint) ;
let formatedEmail = Email && formatThreatDate(Email) ;
let formatedInternet = Internet && formatThreatDate(Internet) ;
 return {
    email: formatedEmail,
    endpoint: formatedEndpoint,
    internet: formatedInternet
 }
}
export const formattedCategoryChartData = (info)=>{
let returnObj = {};
const keyArray = Object.keys(info);
for(let i=0; i<keyArray?.length; i++){
    let dateArr = Object.keys(info[keyArray[i]])
    let data = info[keyArray[i]];
    for(let dateIndex = 0; dateIndex < dateArr.length; dateIndex++){
        returnObj[keyArray[i]] = formatThreatDate(data)
    }
}
return returnObj

}

