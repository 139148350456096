import React from 'react'

import { Input, Label, Select } from "../atoms";
import { FieldStyle } from './form/Form.style';

const Field = ({
  label,
  htmlFor,
  type,
  inputType,
  placeholder,
  children,
  name,
  data,
  formData,
  handleChange,
  dataObj,
  extra,
  max,
  min
}) => {
  return type === "select" ? (
    <FieldStyle >
      <Label htmlFor={htmlFor} children={label} />
      <Select
        name={name}
        placeholder={placeholder}
        data={data}
        dataObj={dataObj}
        formData={formData}
        handleChange={handleChange}
        extra={extra}
      />
    </FieldStyle>
  ) : (
    <FieldStyle >
      <Label htmlFor={htmlFor} children={label} />
      <Input
        type={inputType}
        placeholder={placeholder}
        children={children}
        name={name}
        value={formData[name]}
        handleChange={handleChange}
        purpose="form"
        max={max}
        min={min}
      />
    </FieldStyle>
  );
}

export default Field