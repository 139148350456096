import styled from "styled-components";

export const ButtonStyle = styled.button`
	background-color: ${(props) => (props.fill ? "#96c115" : "#fff")};
	color: ${(props) => (props.fill ? "#fff" : "#96c115")};
	border: ${(props) => (props.fill ? "1px solid #96c115" : "1px solid #fff")};
	padding: 0.75rem 1.375rem;
	border-radius: 5px;
	display: flex;
	outline: transparent;
	cursor: pointer;
	transition: color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
		border-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
		background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-weight: 700;
	font-size: 0.75rem;
	&:hover {
		background-color: ${(props) => (props.fill ? "#7ca212" : null)};
		border-color: #7ca212;
	}
`;

export const SelectStyle = styled.select`
	text-transform: capitalize;
`;

export const LoaderStyle = styled.div`
	width: 100%;
	height: 100%;
	background-color: #fff;
	position: relative;
	z-index: 10;
	display: flex; 
	justify-content: center;
	align-items: center;
`