import React, {useState, useEffect} from 'react'
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {formattedtDataForPieChart} from '../../../utils/formatDataForPie'
import { LineStyle } from '../lineCharts/LineStyle.style';

ChartJS.register(ArcElement, Tooltip, Legend);
const options ={
      plugins: {
        legend: {
      position: 'bottom',
      labels: {
        padding: 15,
        boxHeight: 0,
        font: {
          size: 14
        }
      },
    },
    tooltip: {
      backgroundColor: '#fff',
      titleColor: '#000',
      bodyColor: '#000',
      bodyAlign: 'right',
      borderColor: 'rgba(0, 0, 0, 0.10)',
      borderWidth: 2,
      padding: 10,
      boxPadding: 6,
      cornerRadius: 8,
      caretSize: 0,
      usePointStyle: true,
    }
  }
}
const ThreatSourcePie = ({ data,setId, id }) => {
const [formattedData, setFormattedData] = useState();

useEffect(() => {
   if(data){
    let formated = formattedtDataForPieChart(data);
    setFormattedData(formated)
   }
	
	}, [data]);

    const threatSourcePieData = {
    labels: data && Object.keys(data),
    datasets: [
      {
        label: 'Number of threats',
        data: formattedData && formattedData,
         backgroundColor: [
        'rgba(38, 133, 203, 0.2)',
        'rgba(254, 200, 27, 0.2)',
        'rgba(253, 141, 20, 0.2)',
        'rgba(206, 0, 230, 0.2)',
        'rgba(255, 206, 86, 0.2)',
         ],
         borderColor: [
        'rgba(38, 133, 203, 1)',
        'rgba(254, 200, 27, 1)',
        'rgba(253, 141, 20, 1)',
        'rgba(206, 0, 230, 1)',
        'rgba(255, 206, 86, 1)',
         ],
        borderWidth: 1,
      }],
  }

  return (
    <LineStyle>
        <Doughnut options={options} data={threatSourcePieData} />
    </LineStyle>
  )
// return <>yes</>
}

export default ThreatSourcePie;