import axios from "axios";
const { REACT_APP_BASE_URL} = process.env;

const axiosGet = (url)=>{
      return {
			method: "get",
			url: REACT_APP_BASE_URL + "/" + url,
		}
}
export const getDropDownInfo = async()=>{
    
    try {
			const { data } = await axios(axiosGet("dashboard/get-drop-down-details"));
			return {
				data: data.data,
				error: null,
			};
		} catch (error) {
            return{
                error,
                data: null
            }
        }
}

export const updateThreatData = async(data)=>{
    		const config = {
					method: "post",
					url: REACT_APP_BASE_URL + "/dashboard/add-threat-entry",
					headers: {
						"Content-Type": "application/json",
					},
					data: data,
				};
                try{
				    const request = await axios.request(config);
                    
                    return {response: request.data.message,error: null};
                }catch(error){
                    return { error,response: null }
                }			
}

export const getSelectedThreatCategoryBreakdown = async (
	clickedThreatSourceId,
	duration, year
) => {
	const config = axiosGet(
		`Dashboard/get-threat-entries-by-regions?threatSourceId=${clickedThreatSourceId}&durationType=${duration}&year=${year}`
	);
	try {
		const { data } = await axios(config);
		return {
			data: data.data,
			error: null,
		};
	} catch (error) {
		return {
			error,
			data: null,
		};
	}
};
export const getSelectedThreatSourceBreakdown = async(clickedThreatSourceId, duration, year)=>{
    const config = axiosGet(
			// `Dashboard/get-threat-entries-by-categories?threatSourceId=${clickedThreatSourceId}&durationType=${duration}&year=${year}`
			`Dashboard/get-threat-entries-by-threatSource?threatSourceId=${clickedThreatSourceId}&durationType=${duration}&year=${year}`
		);
 try {
		const { data } = await axios(config);
		return {
			data: data.data,
			error: null,
		};
 } catch (error) {
		return {
			error:error.response.data.message,
			data: null,
		};
 }
}
export const getThreatSourceEntries = async(duration, year)=>{
    const config = axiosGet(`Dashboard/get-all-threat-entries-by-duration?durationType=${duration}&year=${year}`);
    try {
		const { data } = await axios(config);
		return {
			data: data.data,
			error: null,
		}
    } catch (error) {
		return {
			error,
			data: null,
		}
    }
}

export const getThreatSource = async()=>{
    const config = axiosGet(`/Dashboard/get-all-threat-sources`);
 try {
		const { data } = await axios(config);
		return {
			data: data.data,
			error: null,
		};
 } catch (error) {
		return {
			error,
			data: null,
		};
 }
}

export const getARegionBreakdown =async(threatSourceId, regionId, durationType, year)=>{
	const config = axiosGet(
		`/Dashboard/get-threat-entries-by-a-region?threatSourceId=${threatSourceId}&regionId=${regionId}&durationType=${durationType}&year=${year}`
	);
	try{
		const {data}= await axios(config);
		return {
			data: data.data,
			error: null
		}
	}catch(error){
		return {
			error,
			data: null
		}
	}
}
