import React, { useState, useEffect, useRef } from "react";
import ThreatLines from "./ThreatLines";
import { LinedataSetNew } from "./data";
import { formattedCategoryChartData } from "../../../utils/formatChartData";
import { LineStyle, LegendToolTip } from "./LineStyle.style";

const CategoryLines = ({
	data,
	setId,
	id,
	chartTitle,
	selectOptions,
	formData,
	handleChange,
	name,
}) => {
	const [formattedData, setFormattedData] = useState();
	const [showTip, setShowTip] = useState(false);
	const [legendTip, setLegendTip] = useState({
		text: "",
		color: "",
		index: "",
	});
	const threatRef = useRef();
	useEffect(() => {
		if (data) {
			let formated = formattedCategoryChartData(data);
			setFormattedData(formated);
		}
	}, [data]);

	const dataKeys = formattedData && Object.keys(formattedData);
	const threatSourceData = {
		labels: dataKeys && formattedData[dataKeys[0]]?.map((info) => info.date),
		datasets: formattedData && LinedataSetNew(formattedData),
		elements: {
			point: {
				pointStyle: false,
				radius: 0,
			},
		},
	};

	const options = {
		responsive: true,
		interaction: {
			intersect: false,
			mode: "index",
		},
		plugins: {
			legend: {
				position: "bottom",
				labels: {
					padding: 15,
					boxHeight: 0,
					font: {
						size: 14,
					},
				},
				onHover: function (evt, item) {
					let index = item?.datasetIndex;
					setShowTip(true);
					let { text, strokeStyle } = evt.chart.boxes[1]?._hoveredItem;
					setLegendTip({
						text,
						index,
						color: strokeStyle,
					});
					console.log(evt.chart.boxes[1]?._hoveredItem);
				},
				onLeave: function (evt, item) {
					setShowTip(false);
				},
			},
			tooltip: {
				backgroundColor: "#fff",
				titleColor: "#000",
				bodyColor: "#000",
				bodyAlign: "right",
				borderColor: "rgba(0, 0, 0, 0.10)",
				borderWidth: 2,
				padding: 10,
				boxPadding: 6,
				cornerRadius: 8,
				caretSize: 0,
				usePointStyle: true,
			},
		},
		elements: {
			point: {
				pointStyle: false,
			},
		},
		scales: {
			x: {
				// title: {
				// 	display: true,
				// 	text: "Days",
				// 	color: "#96c115",
				// },
				grid: {
					borderDash: [5],
					borderColor: "#DDDFE4",
				},
			},
			y: {
				title: {
					display: true,
					text: "Frequency",
					color: "#96c115",
				},
				grid: {
					tickColor: "transparent",
					borderColor: "#E6E6E6",
				},
				ticks: {
					// stepSize: 100,
					padding: 2,
					showLabelBackdrop: true,
					callback(value) {
						// return `${value / 100}K`;
						return value;
					},
				},
			},
		},
	};
	return (
		<LineStyle>
			<ThreatLines
				data={threatSourceData}
				compRef={threatRef}
				options={options}
				datasetIdKey="id"
			/>
			{showTip && (
				<LegendToolTip color={legendTip.color}>
					<p className="legend-tooltip">
						<span>{legendTip.text}</span> tip with an index of description
						{legendTip.index}
					</p>
				</LegendToolTip>
			)}
		</LineStyle>
	);
};

export default CategoryLines;
