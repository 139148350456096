export const chartData = [
    {
        frequency: 68,
        date: "2023-07-12T00:25:25.3679069",
        threatsource:{
            id: "1",
            source: "internet"
        },
        region:{
            id: "1",
            name: "AMER"
        },
        category: {
            id: "3",
            name: "darkweb3"
        },
    },
    {
        frequency: 120,
        date: "2023-07-12T00:25:25.3679069",
        threatsource:{
            id: "3",
            source: "ussd"
        },
        region:{
            id: "2",
            name: "UK"
        },
        category: {
            id: "1",
            name: "darkweb1"
        },
    },
    {
        frequency: 200,
        date: "2023-07-12T00:25:25.3679069",
        threatsource:{
            id: "2",
            source: "email"
        },
        region:{
            id: "3",
            name: "China"
        },
        category: {
            id: "2",
            name: "darkweb2"
        },
    }
    ,
    {
        frequency: 68,
        date: "2023-07-13T00:25:25.3679069",
        threatsource:{
            id: "2",
            source: "email"
        },
        region:{
            id: "3",
            name: "china"
        },
        category: {
            id: "1",
            name: "darkweb1"
        },
    },
    
    {
        frequency: 168,
        date: "2023-07-13T00:25:25.3679069",
        threatsource:{
            id: "1",
            source: "internet"
        },
        region:{
            id: "1",
            name: "AMER"
        },
        category: {
            id: "1",
            name: "darkweb1"
        },
    },
    {
        frequency: 12,
        date: "2023-07-13T00:25:25.3679069",
        threatsource:{
            id: "3",
            source: "ussd"
        },
        region:{
            id: "2",
            name: "Uk"
        },
        category: {
            id: "3",
            name: "darkweb3"
        },
    },
   
    {
        frequency: 108,
        date: "2023-07-14T00:25:25.3679069",
        threatsource:{
            id: "1",
            source: "internet"
        },
        region:{
            id: "1",
            name: "AMER"
        },
        category: {
            id: "2",
            name: "darkweb2"
        },
    },   
    {
        frequency: 68,
        date: "2023-07-14T00:25:25.3679069",
        threatsource:{
            id: "3",
            source: "ussd"
        },
        region:{
            id: "2",
            name: "UK"
        },
        category: {
            id: "2",
            name: "darkweb2"
        },
    },
    {
        frequency: 50,
        date: "2023-07-14T00:25:25.3679069",
        threatsource:{
            id: "2",
            source: "email"
        },
        region:{
            id: "3",
            name: "China"
        },
        category: {
            id: "1",
            name: "darkweb1"
        },
    }
]