import { RotatingLines } from "react-loader-spinner";
import { LoaderStyle } from "./Atoms.Style";

const Loader =()=>{
    return (
			<LoaderStyle>
				<RotatingLines
					strokeColor="grey"
					strokeWidth="5"
					animationDuration="0.75"
					width="96"
					visible={true}
				/>
			</LoaderStyle>
		);
}
export default Loader
