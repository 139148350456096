import styled from "styled-components";

export const FieldStyle = styled.div`
	margin-top: 1rem;
	width: 100%;
	box-sizing: border-box;
	label {
		font-style: normal;
		font-weight: 700;
		font-size: 0.75rem;
		line-height: 1rem;
		display: block;
		margin-bottom: 0.5rem;
		color: #7ca212;
		text-transform: uppercase;
		letter-spacing: 0.18px;
	}

	input, select {
		outline: transparent;
		border-radius: 2px;
		background-color: #fff;
		border: 1px solid #cccccc;
		line-height: 26px;
		padding: 12px 16px;
		margin: 0 0 16px;
		font-size: 16px;
		height: auto;
		width: 100%;
		&:focus {
			/* box-shadow: 0 0 5px #96c115; */
			box-shadow: 0 0 #7ca212 inset;
			border: 2px solid #7ca212;
		}
	}

`;

export const FormStyle = styled.div`
	background-color: #fff;
	padding-bottom: 2rem ;
	.threat-btn {
		float: right;
	}
`;
