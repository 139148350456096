import React, { useState, useEffect, useRef } from "react";
import ThreatLines from "./ThreatLines";
import { formattedThreatSourceChartData } from "../../../utils/formatChartData";
import { LinedataSetNew } from "./data";
// import { getDatasetAtEvent } from "react-chartjs-2";
import { LineStyle } from "./LineStyle.style";
import { getThreatSourceEntries } from "../../../api/axios";
import { toast } from "react-toastify";
import Loader from "../../atoms/Loader";

const ThreatSourceLines = ({ setId, setClickedThreatSourceId, filter, setLoading, loading }) => {
	const [data, setData] = useState();
	const [formattedData, setFormattedData] = useState();
	const threatRef = useRef();
	async function fetchThreatEntries(duration, year) {
		const { data, error } = await getThreatSourceEntries(duration, year);
		if (!error) {
			setData(data);
			setLoading(false);
		} else {
			toast.error("Oops something went wrong!! Try again later");
		}
	}
	useEffect(() => {
		fetchThreatEntries(filter.threatSourceDuration, filter.year);
	}, [filter]);

	useEffect(() => {
		!loading && data && setFormattedData(formattedThreatSourceChartData(data));
	}, [data, loading]);

	const threatSourceData = {
		labels: formattedData?.internet?.map((info) => info.date),
		datasets: formattedData && LinedataSetNew(formattedData),
		elements: {
			point: {
				pointStyle: false,
				radius: 0,
			},
		},
	};
	const options = {
		responsive: true,
		interaction: {
			intersect: false,
			mode: "index",
		},
		plugins: {
			legend: {
				position: "bottom",
				labels: {
					padding: 15,
					boxHeight: 0,
					font: {
						size: 14,
						family: "Noto Sans NKo Unjoined', sans-serif",
					},
				},

				onClick: function (evt, item) {
					let index = item?.datasetIndex;
					let selected = {
						id: evt.chart?.data?.datasets[index]?.id,
						name: evt.chart?.data?.datasets[index]?.label,
					};
					setId(selected);
				},
			},
			tooltip: {
				backgroundColor: "#fff",
				titleColor: "#000",
				bodyColor: "#000",
				bodyAlign: "right",
				borderColor: "rgba(0, 0, 0, 0.10)",
				borderWidth: 2,
				padding: 10,
				boxPadding: 6,
				cornerRadius: 8,
				caretSize: 0,
				usePointStyle: true,
			},
		},
		elements: {
			point: {
				pointStyle: false,
			},
		},
		scales: {
			x: {
				// title: {
				// 	display: true,
				// 	text: "Days",
				// 	color: "#96c115",
				// },
				grid: {
					borderDash: [5],
					borderColor: "#DDDFE4",
				},
			},
			y: {
				title: {
					display: true,
					text: "Frequency",
					color: "#96c115",
				},
				grid: {
					tickColor: "transparent",
					borderColor: "#E6E6E6",
				},
				ticks: {
					padding: 2,
					showLabelBackdrop: true,
					callback(value) {
						return value;
					},
				},
			},
		},
	};

	return (
		loading ? <Loader /> :<LineStyle>
			<ThreatLines
				data={threatSourceData}
				compRef={threatRef}
				options={options}
				datasetIdKey="id"
			/>
		</LineStyle>
	);
};

export default ThreatSourceLines;
