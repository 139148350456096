import styled from "styled-components";

export const WelcomePageStyle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	#wrapper {
		width: 50%;
		display: flex;
		justify-content: center;
	}
	#wrapper img {
		width: 70%;
		aspect-ratio: 3/2;
		object-fit: contain;
	}
`;

export const ModalFormWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 30%;
	border: 2px solid #7ca212;
	box-shadow: 0 0 10px #7ca212;
	padding: 1rem;
	transition: all 0.3s;
	background-color: #fff;
	border-radius: 0.5rem;
	max-height: 80%;
	overflow-y: auto;
	box-sizing: border-box;
	&:focus {
		outline: #7ca212;
	}
	.top {
		display: flex;
		justify-content: center;
		h2 {
			font-size: 1.2rem;
			line-height: 1.5rem;
			color: #090b17;
			font-style: normal;
			font-weight: bold;
		}
		.close {
			position: absolute;
			width: 0.6rem;
			right: 1rem;
			top: 0.2rem;
		}
		.close svg {
			fill: #959190;
			cursor: pointer;
			transition: fill ease-in-out 0.5s;
			&:hover {
				fill: #7ca212;
			}
		}
	}
	@media (max-width: 768px) {
		width: 50%;
		button {
			margin-bottom: 1rem;
		}
	}
	@media (max-width: 640px) {
		width: 80%;
	}
`;

export const ChartPageStyle = styled.div`
	.right {
		float: right;
	}
`;

export const ChartWrapper = styled.div`
	width: 100%;
	margin: auto;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	padding: 12rem 6rem;
	box-sizing: border-box;
	::-webkit-scrollbar {
		display: none;
	}
	.chart-wrapper {
		box-shadow: 0 4px 14px 4px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		transform: translateY(100vh) translateX(70vw);
		transition: opacity 1.5s ease, transform 1.5s ease;
		display: flex;
		margin: auto;
	}
	.threatSource-wrapper {
	}
	.visible {
		opacity: 1;
		transform: translateY(0) translateX(0);
	}
	.chart-wrapper,
	.selected-wrapper {
		width: 100%;
		margin: auto;
		padding: 4rem 2rem;
		box-sizing: border-box;
	}
	.selected-wrapper {
		padding: 0rem;
		margin-top: 4rem;
		border-radius-top: 10px;

		.region {
			margin: 4rem 2rem;
			box-sizing: border-box;
			width: calc(100% - 4rem);
		}
		.title-wrapper {
			margin-bottom: 4rem;
			width: 100%;
			color: #7ca212;
			background-color: #f8f8f8;
			font-weight: 700;
			p {
				text-align: center;
				font-size: 1rem;
				margin: 0;
				padding: 2rem 0;
			}
			span {
				text-transform: capitalize;
			}
		}
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		width: 100%;
		border-radius: 10px;
		transition: all 0.9s cubic-bezier(0.175, 0.885, 0.32, 1.275);

		box-sizing: border-box;
		.charts-breakdown {
			display: grid;
			grid-template-columns: 2fr 1fr;
			grid-gap: 50px;

			box-sizing: border-box;
			margin: 0 2rem;
		}
	}

	.selected-chart-wrapper {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		width: 100%;
		border-radius: 10px;
		margin-bottom: 4rem;
		transform: translateY(0) translateX(0);
		transition: opacity 1.5s ease, transform 1.5s ease;
		grid-column: auto;
		overflow-x: auto;
		/* padding: 1rem; */

		box-sizing: border-box;
		.filter {
			width: 100%;
			padding-right: 1rem;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
		}
		.title-wrapper {
			margin-bottom: 1rem;
		}
	}
	.pie {
		padding: 0 12%;
		box-sizing: border-box;
	}
	@media (max-width: 1440px) {
		padding: 7.8rem calc((100vw - 1200px) / 2) 0;
		width: 100%;
		margin: initial;
	}
	@media (max-width: 1204px) {
		padding: 8rem calc((100vw - 900px) / 2) 4rem;
		.selected-wrapper .charts-breakdown {
			grid-template-columns: 500px auto;
			grid-gap: 30px;
		}
	}
	@media (max-width: 768px) {
		padding: 8rem calc((100vw - 700px) / 2) 4rem;
		.selected-wrapper .charts-breakdown {
			grid-template-columns: 400px auto;
			grid-gap: 20px;
		}
	}
	@media (max-width: 640px) {
		padding: 8rem calc((100vw - 400px) / 2) 4rem;
		justify-content: center;
		.chart-wrapper,
		.selected-wrapper {
			display: block;
			width: 100%;
		}
		.selected-wrapper {
			padding: 0.5rem;
			.title-wrapper p {
				text-align: left;
				padding: 2rem;
			}
			.charts-breakdown {
				grid-template-columns: auto;
			}
			.region {
				margin: 0;
				box-sizing: border-box;
				width: 100%;
				border-radius: 0;
			}
		}
		.chart-wrapper {
			padding: 2rem 1rem;
		}

		.selected-chart-wrapper {
			box-shadow: none;
			margin-bottom: 0;
		}
	}
`;

export const FilterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-self: flex-end;
	/* align-items: center; */
	margin-bottom: 1.5rem;
	select {
		height: 40px;
		border: 1px solid #bfbfbf;
		border-radius: 4px;
		font-size: 0.75rem;
	}
	p {
		margin-right: 1rem;
		font-weight: 700;
		font-size: 0.75rem;
		color: #7ca212;
	}
	div {
		display: flex;
		select: nth-child(1) {
			margin-right: 0.5rem;
		}
		select {
			outline: transparent;
			&:focus {
				box-shadow: 0 0 5px #7ca212;
				border: 1px solid #7ca212;
			}
		}
	}
`;
