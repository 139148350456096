import React from 'react'
import { Button } from '../../atoms'
import { PrimaryNav, Menu, } from './NavBar.style'
import logo from '../../../assets/images/logo.png'
import { IoAddCircle, IoLogOut } from "react-icons/io5";
import { useMsal } from "@azure/msal-react";

const Navbar = ({ handleOpen }) => {
	const { instance } = useMsal();

	const handleLogout = ()=>{
		  instance.logoutPopup({
				postLogoutRedirectUri: "/",
				mainWindowRedirectUri: "/",
			});

	}
	return (
		<>
			<PrimaryNav>
				<div id="logo-wrapper">
					<img src={logo} alt="Armacell's logo" />
				</div>
				<Menu>
					{/* <MenuLink to="/home" activeStyle>
            Add a new Threat
          </MenuLink> */}

					<div className="desktop">
						<Button onclick={handleOpen} fill="fill">
							Update Threat Data
						</Button>
					</div>
					<div className="mobile icon">
						<IoAddCircle onClick={handleOpen} />
					</div>
					<div className="log-out icon">
						<IoLogOut onClick={handleLogout} />
					</div>
				</Menu>
			</PrimaryNav>
		</>
	);
};
export default Navbar